export const ERROR__FACE_MUST_BE_CENTERED = '40012';
export const ERROR__DECODING_JSON = '40001';
export const ERROR__VALIDATING_JSON = '40002';
export const ERROR__TRANSACTION_NOT_FOUND = '40401';
export const ERROR__INTERNAL_ERROR = '50001';
export const ERROR__INVALID_TRANSACTION_ID = '40004';
export const ERROR__INVALID_TRANSACTION_STATUS = '40009';
export const ERROR__INVALID_TRANSACTION_STATUS_TRANSITION = '40032';
export const ERROR__TOKEN_EXPIRED = '40106';
export const ERROR__UNAUTHORIZED = '40105';
export const ERROR__TOKEN_INVALID = '40102';