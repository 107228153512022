import { templateByTag } from '../screens/sms/utility/handle-tags';

export const getTransactionDetails = async (transactionId, transactionToken, trackEventErrorApi, featureFlags) => {
  const url = `${process.env.NX_PUBLIC_IDPAY_ENDPOINT}/api/customer/v1/${process.env.NX_PUBLIC_IDPAY_PRODUCT}/transactions/${transactionId}`;
  const headers = featureFlags.enableTransactionToken && transactionToken
    ? { authorization: transactionToken }
    : {};

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      ...headers,
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0'
    }
  });

  if (!response.ok) {
    const responseData = await response.text();

    if (trackEventErrorApi) {
      trackEventErrorApi('transactionDetails', url, responseData);
    }

    if (featureFlags.enableTransactionToken) {
      let error;

      try {
        const {
          error: { description, code },
        } = JSON.parse(responseData);

        error = new Error(description);
        error.code = code;
      } catch (e) {
        error = new Error('Response not ok');
      }

      throw error;
    } else {
      throw new Error('Response not ok');
    }
  }

  const {
    company,
    captureConcluded,
    creditCard,
    kind,
    template,
    redirectUrl,
    captureDate,
    concluded,
    identity,
    analyticsId,
    expirationDate,
    tags = [],
    belvoURL,
    step,
  } = await response.json();

  let type = `${kind}_${template.integrationType}`.toUpperCase();

  type = templateByTag(tags, featureFlags.configureTemplateByTag) || type;

  return {
    captureDate,
    company,
    captureConcluded,
    concluded,
    config: {
      ...template,
      type,
      tags,
    },
    identity,
    summary: creditCard,
    redirectUrl,
    analyticsId,
    expirationDate,
    belvoURL,
    step,
  };
};

export const getTransactionPollingDetails = async (transactionId, transactionToken, trackEventErrorApi, enableTransactionToken) => {
  const url = `${process.env.NX_PUBLIC_IDPAY_ENDPOINT}/api/customer/v1/${process.env.NX_PUBLIC_IDPAY_PRODUCT}/transactions/${transactionId}/polling`;
  const headers = enableTransactionToken && transactionToken
    ? { authorization: transactionToken }
    : {};

  const response = await fetch(url, {
    method: 'GET',
    headers
  });

  if (!response.ok) {
    if (trackEventErrorApi) {
      const responseData = await response.text();
      trackEventErrorApi('transactionDetails', url, responseData);
    }

    throw new Error('Response not ok');
  }

  return await response.json();
};

export const shareTransaction = async (transactionId, transactionToken, trackEventErrorApi, enableTransactionToken) => {
  const url = `${process.env.NX_PUBLIC_IDPAY_ENDPOINT}/api/customer/v1/${process.env.NX_PUBLIC_IDPAY_PRODUCT}/transactions/${transactionId}/share`;

  const headers = enableTransactionToken && transactionToken
    ? { authorization: transactionToken }
    : {};

  const response = await fetch(url, {
    method: 'POST',
    headers,
  });

  if (!response.ok) {
    if (trackEventErrorApi) {
      const responseData = await response.text();
      trackEventErrorApi('shareTransaction', url, responseData);
    }

    throw new Error('Response not ok');
  }

  return await response.json();
};

export const refuseTransaction = async (transactionId, transactionToken, trackEventErrorApi, enableTransactionToken) => {
  const url = `${process.env.NX_PUBLIC_IDPAY_ENDPOINT}/api/customer/v1/${process.env.NX_PUBLIC_IDPAY_PRODUCT}/transactions/${transactionId}/refuse`;
  const headers = enableTransactionToken && transactionToken
    ? { authorization: transactionToken }
    : {};

  const response = await fetch(url, {
    method: 'PUT',
    headers
  });

  if (!response.ok) {
    if (trackEventErrorApi) {
      const responseData = await response.text();
      trackEventErrorApi('refuseTransaction', url, responseData);
    }

    throw new Error('Response not ok');
  }

  return await response.json();
};

export const notifyAbsentHolderTransaction = async (transactionId, transactionToken, trackEventErrorApi, enableTransactionToken) => {
  const url = `${process.env.NX_PUBLIC_IDPAY_ENDPOINT}/api/customer/v1/${process.env.NX_PUBLIC_IDPAY_PRODUCT}/transactions/${transactionId}/absent`;
  const headers = enableTransactionToken && transactionToken
    ? { authorization: transactionToken }
    : {};

  const response = await fetch(url, {
    method: 'PUT',
    headers
  });

  if (!response.ok) {
    if (trackEventErrorApi) {
      const responseData = await response.text();
      trackEventErrorApi('notifyAbsentHolderTransaction', url, responseData);
    }

    throw new Error('Response not ok');
  }

  return await response.json();
};

export const validateTransaction = async ({
  transactionId,
  transactionToken,
  collectionID,
  selfie,
  name,
  source,
  trackEventErrorApi,
  enableTransactionToken,
}) => {
  const responseBody = {
    selfie,
    source,
    name,
    collectionID,
  };

  const url = `${process.env.NX_PUBLIC_IDPAY_ENDPOINT}/api/customer/v1/${process.env.NX_PUBLIC_IDPAY_PRODUCT}/transactions/${transactionId}/validate`;

  const headers = enableTransactionToken && transactionToken
    ? { 'content-type': 'application/json', authorization: transactionToken }
    : { 'content-type': 'application/json' };

  const response = await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(responseBody),
  });

  if (!response.ok) {
    const responseData = await response.text();
    if (trackEventErrorApi) {
      trackEventErrorApi('validateTransaction', url, responseData, responseBody);
    }

    let error;

    try {
      const {
        error: { description, code },
      } = JSON.parse(responseData);

      error = new Error(description);
      error.code = code;
    } catch (e) {
      error = new Error('Response not ok');
    }

    throw error;
  }

  return await response.json();

};

export const frictionlessValidateTransaction = async (transactionId, transactionToken, collectionID, name, source, trackEventErrorApi, featureFlags = {}) => {
  const responseBody = {
    collectionID,
    source,
    name,
  };

  const url = `${process.env.NX_PUBLIC_IDPAY_ENDPOINT}/api/customer/v1/${process.env.NX_PUBLIC_IDPAY_PRODUCT}/transactions/${transactionId}/validate/frictionless`;
  const headers = featureFlags?.enableTransactionToken && transactionToken
    ? { 'content-type': 'application/json', authorization: transactionToken }
    : { 'content-type': 'application/json' };

  const response = await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(responseBody),
  });

  if (!response.ok) {
    const responseData = await response.text();
    if (trackEventErrorApi) {
      trackEventErrorApi('frictionlessValidateTransaction', url, responseData, responseBody);
    }

    let error;

    try {
      const {
        error: { description, code },
      } = JSON.parse(responseData);

      error = new Error(description);
      error.code = code;
    } catch (e) {
      error = new Error('Response not ok');
    }

    throw error;
  }

  return await response.json();
};

export const belvoValidateTransaction = async (transactionId, transactionToken, trackEventErrorApi, featureFlags, status, description) => {

  const responseBody = {
    status,
    description,
  };

  const url = `${process.env.NX_PUBLIC_IDPAY_ENDPOINT}/api/customer/v1/${process.env.NX_PUBLIC_IDPAY_PRODUCT}/transactions/${transactionId}/validate/belvo`;
  const headers = featureFlags.enableTransactionToken && transactionToken
    ? { 'content-type': 'application/json', authorization: transactionToken }
    : { 'content-type': 'application/json' };

  const response = await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(responseBody),
  });

  if (!response.ok) {
    const responseData = await response.text();
    if (trackEventErrorApi) {
      trackEventErrorApi('belvoValidateTransaction', url, responseData, responseBody);
    }

    let error;

    try {
      const {
        error: { description, code },
      } = JSON.parse(responseData);

      error = new Error(description);
      error.code = code;
    } catch (e) {
      error = new Error('Response not ok');
    }

    throw error;
  }

  return await response.json();
};


export const holderDefinitionTransaction = async (holderDefinitionData, transactionData, trackAPIError, featureFlags) => {

  const url = `${process.env.NX_PUBLIC_IDPAY_ENDPOINT}/api/customer/v1/${process.env.NX_PUBLIC_IDPAY_PRODUCT}/transactions/${transactionData.transactionId}/holder/definition`;

  const headers = featureFlags?.enableTransactionToken && transactionData?.transactionToken
    ? { 'content-type': 'application/json', authorization: transactionData.transactionToken }
    : { 'content-type': 'application/json' };

  const response = await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(holderDefinitionData),
  });

  if (!response.ok) {
    const responseData = await response.text();
    if (trackAPIError) {
      trackAPIError('holderDefinitionTransaction', url, responseData);
    }

    let error;

    try {
      const {
        error: { description, code },
      } = JSON.parse(responseData);

      error = new Error(description);
      error.code = code;
    } catch (e) {
      error = new Error('Response not ok');
    }

    throw error;
  }

  return await response.json();
};
