export const getToken = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('token');
};

export const removeTokenFromUrl = () => {
  const urlObj = new URL(window.location.href);
  urlObj.searchParams.delete('token');
  return urlObj.toString();
};
