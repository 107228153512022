import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';

export const initNewRelic = () => {
  if (!process.env.NX_PUBLIC_NR_ACCOUNT_ID) {
    return;
  }

  const credentials = {
    accountID: process.env.NX_PUBLIC_NR_ACCOUNT_ID,
    agentID: process.env.NX_PUBLIC_NR_AGENT_ID,
    licenseKey: process.env.NX_PUBLIC_NR_LICENSE_KEY,
    applicationID: process.env.NX_PUBLIC_NR_APPLICATION_ID,
    trustKey: process.env.NX_PUBLIC_NR_TRUST_KEY,
    beacon: process.env.NX_PUBLIC_NR_BEACON,
    errorBeacon: process.env.NX_PUBLIC_NR_ERROR_BEACON,
    sa: process.env.NX_PUBLIC_NR_SA,
    allowedOrigins: [process.env.NX_PUBLIC_HOST],
  };

  const config = {
    init: {
      distributed_tracing: {
        enabled: true,
        cors_use_newrelic_header: true,
        allowed_origins: credentials.allowedOrigins,
      },
      privacy: {
        cookies_enabled: true,
      },
      obfuscate: [
        {
          regex: /#access_token=[A-Za-z0-9.\-\_&=#*+]+/g,
          replacement: 'access_token'
        },
        {
          regex: /#code=[A-Za-z0-9.\-\_&=#*+]+/g,
          replacement: 'code'
        },
        {
          regex: /#token=[A-Za-z0-9.\-\_&=#*+]+/g,
          replacement: 'token'
        },
      ]
    },
    info: {
      beacon: credentials.beacon,
      errorBeacon: credentials.errorBeacon,
      licenseKey: credentials.licenseKey,
      applicationID: credentials.applicationID,
      sa: credentials.sa,
    },
    loader_config: {
      accountID: credentials.accountID,
      trustKey: credentials.trustKey,
      agentID: credentials.agentID,
      licenseKey: credentials.licenseKey,
      applicationID: credentials.applicationID,
    },
  };

  return new BrowserAgent(config);
};